import * as React from "react";
import { LearnerUnit } from "../../../../models/LearnerUnit";
import { MediaRequestFile } from "../../../../models/media-request";

import { FormattedDate, FormattedTime } from "react-intl";
import { useState } from "react";
import TextHelper from "../../shared/text-formatting";
import  moment from "moment";
import _ from "lodash";
import * as avatsFileStatus from '../../shared/constants';
// import LastLearnerConfirmationModal from '../../../../components/last-learner-confirmation-modal'
import groupImage from "../group-18.svg";
import infoFillImage from "../info-fill-18.svg";
import deleteImage from "../delete-18.svg";
import { Table, Tooltip } from 'ukas.uux.react-components';

export interface LearnerMediaListProps {
  files: MediaRequestFile[];
  learnerUnit: LearnerUnit;
  isBusy: boolean;
  onRemoveFile: (
    file: MediaRequestFile,
    learnerUnit: LearnerUnit,
    event: React.UIEvent<HTMLSpanElement>
  ) => void;
  canRemoveFiles: boolean;
  islastLinkedLearner: boolean;
  getlastLinkedLearnerConfirmationMsg: () => JSX.Element;
  cancellastLinkedLearnerModalHandler: ()=> void;
  successlastLinkedLearnerModalHandler: ()=> void;


}



const LearnerMediaList = ({
  files,
  learnerUnit,
  onRemoveFile,
  canRemoveFiles,
  isBusy,
  islastLinkedLearner,
  getlastLinkedLearnerConfirmationMsg,
  cancellastLinkedLearnerModalHandler,
  successlastLinkedLearnerModalHandler

}: LearnerMediaListProps) => {
  const [selectedColumn] = useState<string>("fileName");
  const [direction] = useState<string>("up");
  const [searchText] = useState("");
 
 
  // const text = {
  //   headerTitle: "Last Linked Learner Notification",
  //   bodyText: "",
  //   closeButtonSRText: "Cancel",          
  //   modalSaveButtonText: "Ok",
  //   modalCancelButtonText: "Cancel"
  // };
  files =
    selectedColumn === ""
      ? filter(files, searchText)
      : sort(files, selectedColumn, direction);
      
  files = files.filter(file=>file.learnerIds && file.learnerIds.includes(learnerUnit.learnerId))

  {
    if (!isBusy) {

      return (
        <div>
          <div>
          <span className="pe-label pe-bold">Learning Provider:</span>&nbsp;
            <span className="pe-label">
              {learnerUnit.learningProviderCode!= null ? learnerUnit.learningProviderCode : ''}&nbsp;{learnerUnit.learningProviderName!= null ? learnerUnit.learningProviderName : ''}
            </span>
            <br />
            <span className="pe-label pe-bold">Learner:</span>&nbsp;
            <span className="pe-label">
              {learnerUnit.learnerCode!= null? learnerUnit.learnerCode : ''}&nbsp;{learnerUnit.firstName}&nbsp;
              {learnerUnit.surname}
            </span>
            <br />
            <span className="pe-label pe-bold">Unit:</span>&nbsp;
            <span className="pe-label">
              {learnerUnit.unitId}&nbsp;{learnerUnit.unitName}
            </span>
          </div>
          <br />
          {/* <table className="pe-table"> */}
          <div className="scrollTable">
          <Table responsive="xl">
            <thead>
              <tr>
                <td>File name</td>
                <td>Upload details</td>
                <td>File Status</td>
                {canRemoveFiles && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {files.map((file: MediaRequestFile) => (
                
                file.fileName && (
                  
                <tr>
                  <td>{file.fileName}{" "}
                      { (file.isUploadByFile && file.learnerIds.length >1) && (<div className="groupIcon">
                        {
                         <img src={groupImage}/>}
                        {/* <PearsonElementsSDK.Icon name="group-18" className="infoFillIcon" /> */}
                        &nbsp; </div>) }
                    
                        <div style={{ color: "grey", fontSize: "12px" }}>
                          {file.fileSize}
                        </div>
                    </td>
                
                  
                  <td>
                    <FormattedDate
                      value={file.createdDateTime}
                      year="numeric"
                      month="numeric"
                      day="numeric"
                    />
                    &nbsp;
                    <FormattedTime value={file.createdDateTime} />
                  </td>
                  <td>{file.fileStatus === 'PREPARING' && (
                    <span>Scanning</span>)}{file.fileStatus === 'READY' && (
                    <span>Accepted</span>)}
                    {file.fileStatus === 'REJECTED'  && (
                    <span>                    
                          <Tooltip
                            className="tooltip-top-rejected"
                            id="tooltip-top"
                            placement="top"
                            maxWidth="400px"
                            value={getRejectedReason(file.rejectedReason + '', file.fileName, file.rejectedFiles.length)}
                          >
                            <span className={((file.fileName.includes('.zip') || file.fileName.includes('.rar')) && file.rejectedReason === 'Infected') ? "tooltiptext-ziprejectrReason" : "tooltiptext-rejectrReason"}>
                              <img src={infoFillImage} />
                            </span>
                          </Tooltip>
                      Rejected
                    </span>)}
                  </td>
                  {canRemoveFiles &&  (
                    
                    <td>
                     { file.fileStatus !== 'PREPARING' && (
                      <span
                        onClick={onRemoveFile.bind(event, file, learnerUnit)}
                      >
                        <img src={deleteImage}/>
                        {/* <PearsonElementsSDK.Icon name="delete-18" /> */}
                      </span>) }
                    </td>
                  )}
                </tr>)
              ))}
            </tbody>
          </Table>
      
          </div>
          {/* <div className={"second-modal-positioning"} >
          <PearsonElementsSdk.Modal
          isShown={islastLinkedLearner}
          text={text}
          footerVisible={false}
          cancelBtnHandler={cancellastLinkedLearnerModalHandler}
          successBtnHandler={successlastLinkedLearnerModalHandler}
        >
          <LastLearnerConfirmationModal
          
            cancelBtnHandler={cancellastLinkedLearnerModalHandler}
            successBtnHandler={successlastLinkedLearnerModalHandler} 
            conmfirmMsgRender = {getlastLinkedLearnerConfirmationMsg}
          />
        </PearsonElementsSdk.Modal>
        </div> */}
          {/* </table> */}
        </div>
      );
    }
    return (
      <div className="pe-template__single content">
        <section>
          <div>Loading...</div>
        </section>
      </div>
    );
  }
};

const getRejectedReason = (responseRejectedReason: string,fileName:string,fileCount:number):string =>{
  
  switch(responseRejectedReason.toUpperCase()){
    case avatsFileStatus.AVATS_STATUS_INFECTED:
        return (fileName.includes('.zip') || fileName.includes('.rar'))?  avatsFileStatus.AVATS_STATUS_INFECTED_ZIP_TEXT.replace('[X]',fileCount.toString()):avatsFileStatus.AVATS_STATUS_INFECTED_TEXT  
    case avatsFileStatus.AVATS_STATUS_UNSUPPORTED:
      return avatsFileStatus.AVATS_STATUS_UNSUPPORTED_TEXT
    case avatsFileStatus.AVATS_STATUS_PASSWORD_PROTECTED:
      return avatsFileStatus.AVATS_STATUS_PASSWORD_PROTECTED_TEXT
    default:
      return avatsFileStatus.AVATS_STATUS_DEFAULT_TEXT
  }
}



const filter = (
  files: MediaRequestFile[],
  searchText: string
): MediaRequestFile[] => {
  const searchTerm = searchText;
  if (searchTerm !== "" && searchTerm.length >= 3) {
    const mediaRequests = JSON.parse(JSON.stringify(files));
    const columnNames = ["fileName", "createdDateTime", "fileStatus"];
    return _.filter(mediaRequests, mediaRequest => {
      mediaRequest.status = TextHelper.titleCaseUploadStatus(
        mediaRequest.status
      );
      mediaRequest.deadlineDateTime = moment(
        new Date(mediaRequest.deadlineDateTime)
      ).format("DD MMM YYYY");
      return _(mediaRequest)
        .pick(columnNames)
        .some(searchstring => {
          return _(searchstring)
            .toLower()
            .includes(searchTerm);
        });
    }) as MediaRequestFile[];
  }
  return files;
};

const sort = (
  files: MediaRequestFile[],
  selectedColumn: string,
  direction: string
): MediaRequestFile[] => {
  const lodashDirection = direction === "down" ? "desc" : "asc";
  const sortedSampleMediaRequestFiles = _.orderBy(
    files,
    [
      mediaRequestFile => {
        // Added this condition to counteract page crash if
        // field has no value
        if (!mediaRequestFile[selectedColumn]) {
          return null;
        }
        if (
          typeof mediaRequestFile[selectedColumn].toLowerCase === "function"
        ) {
          return mediaRequestFile[selectedColumn].toLowerCase();
        } else {
          return mediaRequestFile[selectedColumn];
        }
      }
    ],
    [lodashDirection]
  ) as MediaRequestFile[];
  return filter(sortedSampleMediaRequestFiles, "");
};

export default LearnerMediaList;
