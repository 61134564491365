import * as React from 'react';

require("@webcomponents/webcomponentsjs/webcomponents-bundle");
require("@webcomponents/webcomponentsjs/custom-elements-es5-adapter");

require("@pearson-ux/alert/js/dist/pearson-alert")

interface ErrorAlertProps {
  showAlert: boolean;
  alertTitle: string;
  children: JSX.Element[] | JSX.Element
  onClose: () => void;
}
const ErrorAlert = ({
    showAlert = false,
    alertTitle,
    children,
    onClose
}: ErrorAlertProps) => {
    if (showAlert) {

        // listener for pearson alert closing to reset state
        window.addEventListener('dismiss', () => {
            onClose();
        }, true);

        return (
            <div style={{position: 'fixed', top: '220px', right: '10px', width: '350px', zIndex: 100000}}>                              
                <pearson-alert level="inline" type="error" animated="true" returnNode="#divReturnHack">
                    <div>
                        <h2 id="alertTitle" className="pe-label alert-title">
                            <strong>{alertTitle}</strong>
                        </h2>
                        <br /><br />
                        <p id="alertText" className="pe-paragraph alert-text">
                            {children}
                        </p>
                    </div>
                </pearson-alert>
                <div id="divReturnHack">&nbsp;</div>
            </div>
        );
    }

    return <div>&nbsp;</div>;
};

export default ErrorAlert;
