import * as React from "react";
import _ from "lodash";
import * as toastr from "toastr";

import { FormattedDate, FormattedTime } from "react-intl";
import { MediaRequest, MediaRequestFile } from "../../../models/media-request";

import { Auth } from "../../../models/auth";
import Config from "../../../app-config";
import ConfirmationModalGeneric from '../../../components/confirmation-modal-generic';
import ErrorAlert from "../../../components/common/error-alert";
import { GlobalAlertMessage } from "../../../models/global-alert-message";
import LastLearnerConfirmationModal from '../../../components/last-learner-confirmation-modal'
import LearnerMediaFileList from "./shared/sample-media-request-files-list";
import { LearnerUnit } from "../../../models/LearnerUnit";
import ManageUploadModal from "./shared/manage-upload-modal";
import { MediaUploadFile } from "../../../models/media-upload";
import { MediaUploadStatus } from "../../../models/media-upload";
import { RouteComponentProps } from "react-router-dom";
import SampleMediaRequestsLearnersList from "./shared/sample-media-requests-learners-list";
import SuccessAlert from "../../../components/common/success-alert";
import TextHelper from "../shared/text-formatting";
import { userTypeEnums } from "../shared/enum";

// rnerMediaFileTabProps from './shared/sample-media-request-files-tab-list'









const PearsonElementsSdk = require("@pearson-components/modal");
const pageTitle = "Learner Material Upload";
export interface StateProps {
  learningProviderId: string;
  sampleMediaRequest: MediaRequest | null;
  mediaUploadStatus: MediaUploadStatus[] | null;
  auth: Auth;
  showAddLearnerError:boolean
 
}

export interface DispatchProps {
  upload: (uploadFile: MediaUploadFile) => Promise<void>;
  submitMediaRequest: (
    mediaRequest: MediaRequest,
    mediaSetSubmittedBy: string
  ) => Promise<void>;
  removeFileFromLearner: (
    mediaRequest: MediaRequest,
    file: MediaRequestFile,
    learnerUnit: LearnerUnit
  ) => Promise<void>;
  raiseAlert: (globalAlertMessage: GlobalAlertMessage) => void;
  getSampleMediaRequests: (learningProviderId: string) => Promise<void>;
  refreshSampleMediaRequest: (mediaRequest: MediaRequest) => Promise<void>;
  getMediaSetsByLearningProviderCode: (
    learningProviderCode: string
  ) => Promise<void>;
  updateLearners:(mediaRequestFiles:LearnerUnit | any, sampleMediaRequest: MediaRequest, action: string, mediaRequestFileSelected: MediaRequestFile ) => Promise<void>;
  closeAddLearnerErrorModal:() => void;
  showAddLearnerErrorModal:() => void;
  removeFile: (
    learningProviderId : string,
    mediaSetId: string,
    fileId: string) => Promise<void>;
}

export type OwnProps = RouteComponentProps<{ sampleMediaRequestId: string }>;

interface Props extends DispatchProps, StateProps, OwnProps {}

interface State {
  isBusy: boolean;
  selectedSampleDeadline: Date;
  selectedColumn: string;
  direction: string;
  selectedUnit: { item: string; value: string };
  uploadsInProgress: number;
  showModal: boolean;
  showLearnerModal: boolean;
  showUBFRemoveFileModal: boolean;
  selectedLearnerUnitCombo: LearnerUnit | null;
  showSubmitModal: boolean;
  islastLinkedLearner: boolean;
  selectedFile: MediaRequestFile | null;
  selectedLearnerUnit: LearnerUnit| null;
  submitText: {
    headerTitle: string;
    bodyText: string;
    closeButtonSRText: string;
    modalSaveButtonText: string;
    modalCancelButtonText: string;
  };
  recallAlertText: {
    headerTitle: string;
    bodyText: string;
    modalCancelButtonText: string;
  };
  mediaRequestFileSelected: MediaRequestFile | null;
  submissionSuccess: boolean;
  submissionError: boolean;
  refreshTimer: NodeJS.Timeout;
  onLoadSort: boolean;
  showAddLearnerError:boolean;
  showRecallAlertModal: boolean;
  selectedLearnerCode:string;
  selectedLearnerName:string;
  selectedLearners: LearnerUnit[] | null;
}

export class SampleMediaRequestsLearners extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    document.title = pageTitle;
    this.state = {
      isBusy: true,
      selectedSampleDeadline: new Date(),
      selectedColumn: "",
      direction: "",
      selectedUnit: { item: "All Units", value: "0" },
      uploadsInProgress: 0,
      showModal: false,
      showLearnerModal: false,
      showUBFRemoveFileModal: false,
      islastLinkedLearner: false,
      mediaRequestFileSelected: null,
      selectedLearnerUnitCombo: null,
      selectedFile:null,
      selectedLearnerUnit:null,
      showSubmitModal: false,
      showAddLearnerError: false,
      submitText: {
        headerTitle: "Submit sample",
        bodyText: "",
        closeButtonSRText: "Cancel",
        modalSaveButtonText: "Ok",
        modalCancelButtonText: "Cancel"
      },
      recallAlertText: {
        headerTitle: "Sample Request Recall Notification",
        bodyText: "",
        modalCancelButtonText: "Close"
      },
      submissionSuccess: false,
      submissionError: false,
      refreshTimer: null as unknown as NodeJS.Timeout,
      onLoadSort: true,
      showRecallAlertModal: false,
      selectedLearnerCode: '',
      selectedLearnerName:'',
      selectedLearners:[]
    };

    this.goBack = this.goBack.bind(this);
    this.canSubmit = this.canSubmit.bind(this);
    this.submit = this.submit.bind(this);
    this.onSelectUnit = this.onSelectUnit.bind(this);
    this.getUnits = this.getUnits.bind(this);
    this.handleColumnSort = this.handleColumnSort.bind(this);
    this.getIconName = this.getIconName.bind(this);
    this.uploadFileEvent = this.uploadFileEvent.bind(this);
    this.cancelModalHandler = this.cancelModalHandler.bind(this);
    this.successModalHandler = this.successModalHandler.bind(this);
    this.cancelSubmitModalHandler = this.cancelSubmitModalHandler.bind(this);
    this.successSubmitModalHandler = this.successSubmitModalHandler.bind(this);
    this.onSubmissionSuccessAlertClose = this.onSubmissionSuccessAlertClose.bind(
      this
    );
    this.onSubmissionErrorAlertClose = this.onSubmissionErrorAlertClose.bind(
      this
    );
    this.showFiles = this.showFiles.bind(this);
    this.sort = this.sort.bind(this);
    this.getIconName = this.getIconName.bind(this);
    this.showFiles = this.showFiles.bind(this);
    this.onRemoveFile = this.onRemoveFile.bind(this);
    this.checkBoxAll = this.checkBoxAll.bind(this);
    this.onSelectLearner = this.onSelectLearner.bind(this);
    this.refreshMediaRequest = this.refreshMediaRequest.bind(this);
    this.showLearners = this.showLearners.bind(this);
    this.onSelectCheckAll = this.onSelectCheckAll.bind(this);
    this.cancelLearnerModalHandler = this.cancelLearnerModalHandler.bind(this);
    this.successUBFRemoveFileModalHandler = this.successUBFRemoveFileModalHandler.bind(this);
    this.cancelUBFRemoveFileModalHandler = this.cancelUBFRemoveFileModalHandler.bind(this);
    this.onUBFRemoveFile = this.onUBFRemoveFile.bind(this);
    this.closeLearnerModalHandler = this.closeLearnerModalHandler.bind(this);
    this.successLearnerModalHandler = this.successLearnerModalHandler.bind(this);    
    this.handleUBFRemoveFile = this.handleUBFRemoveFile.bind(this);
    this.closeRecallAlertModalHandler = this.closeRecallAlertModalHandler.bind(this);
    this.onShowAddLearnerErrorClose = this.onShowAddLearnerErrorClose.bind(this);
    this.cancellastLinkedLearnerModalHandler = this.cancellastLinkedLearnerModalHandler.bind(this);
    this.successlastLinkedLearnerModalHandler = this.successlastLinkedLearnerModalHandler.bind(this);
    this.getlastLinkedLearnerConfirmationMsg = this.getlastLinkedLearnerConfirmationMsg.bind(this);
    this.isConsortia = this.isConsortia.bind(this);       
  }

  componentDidMount() {
    //set media set refresh interval
    const interval = setInterval(()=> this.refreshMediaRequest(), 20000);
    this.setState({refreshTimer: interval});
  }
  
  componentWillUnmount() {
    this.setState({showAddLearnerError: false});

    //clear media set refresh interval
    clearInterval(this.state.refreshTimer);
  }

  render() {

    const lastLearnerErrorText = {
      headerTitle: "Last Linked Learner Notification",
      bodyText: "",
      closeButtonSRText: "Cancel",          
      modalSaveButtonText: "Ok",
      modalCancelButtonText: "Cancel"
    }
    const text = {
      headerTitle: "Select Learner",
      bodyText: "Select learner(s) to link to the uploaded file.",
      closeButtonSRText: "Cancel",          
      modalSaveButtonText: "Ok",
      modalCancelButtonText: "Cancel"
    };

    const confirmUBFRemoveFiletext = {
      headerTitle: 'File Removal Confirmation',
      bodyText: '',
      closeButtonSRText: 'Cancel',
      modalSaveButtonText: 'Ok',
      modalCancelButtonText: 'Cancel'
    };

    const {
      showModal,
      selectedLearnerUnitCombo: selectedLearnerUnit,
      showSubmitModal,
      submitText,
      recallAlertText,
      submissionSuccess,
      submissionError,
      showRecallAlertModal,
      selectedColumn
    } = this.state;

    return (
      <div className="learners">
        <div className="pe-template__single">
          <h1 className="pe-page-title">{pageTitle}</h1>
        </div>
        <div className="pe-template__single content">
          <section>
            <p className="pe-strategy__centered--small-gap">
            This screen shows the chosen unit(s) and learner(s) within the 
            request for your Learning Provider.  View all units or select a 
            specific unit to upload material.  If the 'Upload by File' method 
            is used, you must link at least one learner to the uploaded 'Accepted' file.
            </p>

            {this.props.sampleMediaRequest !== null &&
              this.props.sampleMediaRequest !== undefined &&
              this.props.sampleMediaRequest.learnerUnits !== null && (
                <div>
                  <div className="pe-row">
                    <div className="pe-col-6">
                      <div className="pe-label small-gap">
                        <span className="pe-label pe-label--bold">
                          Qualification
                        </span>
                        <br />
                        {this.props.sampleMediaRequest.qualificationGroupCode !=
                        null
                          ? this.props.sampleMediaRequest
                              .qualificationGroupCode +
                            " " +
                            this.props.sampleMediaRequest.qualificationName
                          : this.props.sampleMediaRequest.qualificationName}
                        &nbsp;
                        <b>
                          {this.props.sampleMediaRequest.consortiaId
                            ? "(C)"
                            : ""}
                        </b>
                        &nbsp;
                        <b className="second-sample-text-color">{this.props.sampleMediaRequest.parentRequests && this.props.sampleMediaRequest.parentRequests.length > 0 ? "("+(this.props.sampleMediaRequest.parentRequests.length + 1)+")" : ""}</b>
                      </div>
                    </div>
                    <div className="pe-col-6">
                      <div className="pe-label small-gap">
                        <span className="pe-label pe-label--bold">
                          Sample Deadline
                        </span>
                        <br />
                        {(this.props.sampleMediaRequest.deadlineDateTime && this.props.sampleMediaRequest.deadlineDateTime !== undefined && this.props.sampleMediaRequest.deadlineDateTime !== null )   && (
                        <FormattedDate
                          value={this.props.sampleMediaRequest.deadlineDateTime}
                          year="numeric"
                          month="numeric"
                          day="numeric"
                        />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="pe-row">
                    <div className="pe-col-6">
                      <div className="pe-label small-gap">
                        <span className="pe-label pe-label--bold">
                          Standards Verifier
                        </span>
                        <br />
                        {this.props.sampleMediaRequest.standardsVerifierName}
                      </div>
                    </div>{" "}
                    <div className="pe-col-6">
                      <div className="pe-label small-gap">
                        <span className="pe-label pe-label--bold">Status</span>
                        <br />
                        {TextHelper.titleCaseUploadStatus(
                          this.props.sampleMediaRequest.status
                        )}
                      </div>
                    </div>
                  </div>

                  {this.props.sampleMediaRequest.status ===
                    "UPLOAD_SUBMITTED" && (
                    <div className="pe-row">
                      <div className="pe-col-6">
                        <div className="pe-label small-gap">
                          <span className="pe-label pe-label--bold">
                            Sample submitted on
                          </span>
                          <br />
                          <FormattedDate
                            value={this.props.sampleMediaRequest.submissionDate}
                          />{" "}
                          {
                            <FormattedTime
                              value={
                                this.props.sampleMediaRequest.submissionDate
                              }
                            />
                          }{" "}
                          {this.props.sampleMediaRequest.submittedBy &&
                            this.props.sampleMediaRequest.submittedBy
                              .length && (
                              <span>
                                {" "}
                                by {this.props.sampleMediaRequest.submittedBy}
                              </span>
                            )}
                        </div>
                      </div>{" "}
                      <div className="pe-col-6" />
                    </div>
                  )}

                  <SampleMediaRequestsLearnersList
                     sampleMediaRequestLearners={
                      selectedColumn === ""
                        ? this.filterLearnersByUnit(
                            this.props.sampleMediaRequest.learnerUnits,
                            this.state.selectedUnit.value
                          )
                        : this.sort(this.props.sampleMediaRequest.learnerUnits)
                    }
                    sampleMediaRequest={this.props.sampleMediaRequest}
                    mediaRequestFiles={this.props.sampleMediaRequest.files}
                    units={this.getUnits(
                      this.props.sampleMediaRequest.learnerUnits
                    )}
                    showLearners={this.showLearners}
                    onSelectUnit={this.onSelectUnit}
                    goBack={this.goBack}
                    uploadFileEvent={this.uploadFileEvent}
                    unitFilterLabel={this.state.selectedUnit.item}
                    showFiles={this.showFiles}
                    onSort={this.handleColumnSort}
                    getIconName={this.getIconName}
                    allowUpload={
                      this.props.sampleMediaRequest.status ===
                        "AWAITING_UPLOAD" ||
                      this.props.sampleMediaRequest.status ===
                        "UPLOAD_IN_PROGRESS" ||
                      this.props.sampleMediaRequest.status ===
                        "UPLOAD_NOT_SUBMITTED" ||
                      this.props.sampleMediaRequest.status ===
                        "UPLOAD_RETURNED" ||
                      this.props.sampleMediaRequest.status ===
                        "REQUEST_RECALLED"
                    }
                    canSubmit={this.canSubmit}
                    submit={this.submit}
                    onLoadSort={this.state.onLoadSort}
                    onUBFRemoveFile={this.onUBFRemoveFile}
                    isConsortia={this.isConsortia()}
                    // isConsortia={ 
                    //   this.props.sampleMediaRequest.consortiaId ? true : false
                    // }
                  />
                  <ManageUploadModal
                    showModal={showModal}
                    cancelModalHandler={this.cancelModalHandler}
                    successModalHandler={this.successModalHandler}
                    filterFilesForLearner={this.filterFilesForLearner}
                    learnerUnit={selectedLearnerUnit!}
                    islastLinkedLearner={this.state.islastLinkedLearner}
                    cancellastLinkedLearnerModalHandler={this.cancellastLinkedLearnerModalHandler}
                    successlastLinkedLearnerModalHandler={this.successlastLinkedLearnerModalHandler}
                    getlastLinkedLearnerConfirmationMsg={this.getlastLinkedLearnerConfirmationMsg}
                    files={this.props.sampleMediaRequest.files}
                    onRemoveFile={this.onRemoveFile}
                    canRemoveFiles={
                      this.props.sampleMediaRequest.status !== "UPLOAD_SUBMITTED" && this.props.sampleMediaRequest.status !== "SUBMIT_PENDING"
                    }
                    isBusy={this.state.isBusy}
                  />
                </div>
              )}
          </section>
        </div>
        <PearsonElementsSdk.Modal
          isShown={showSubmitModal}
          text={submitText}
          footerVisible={true}
          cancelBtnHandler={this.cancelSubmitModalHandler}
          successBtnHandler={this.successSubmitModalHandler}
        >
          <span>You are confirming:</span>
          <ul>
            <li>
              Every learner within every unit in this sample has the required
              materials uploaded
            </li>
            <li>The sample is ready for Standards Verification </li>
          </ul>
          <span>Do you want to proceed?</span>
        </PearsonElementsSdk.Modal>
        <PearsonElementsSdk.Modal
          isShown={showRecallAlertModal}
          text={recallAlertText}
          footerVisible={true}
          saveBtnId="disableSuccessBtn"
          cancelBtnHandler={this.closeRecallAlertModalHandler}
        >
          <p>
            The Standards Verifier has recalled this sample request. Until it is
            submitted back to your learning provider you cannot manage this
            sample request. Please close this message to return back to the
            Sample Request screen.
          </p>
        </PearsonElementsSdk.Modal>
      

        <PearsonElementsSdk.Modal
          isShown={this.state.showLearnerModal}
          text={text}
          footerVisible={false}
          cancelBtnHandler={this.cancelLearnerModalHandler}
          successBtnHandler={this.successLearnerModalHandler}
        >
          <LearnerMediaFileList
            isBusy={this.state.isBusy}
            sampleMediaRequest={this.props.sampleMediaRequest}
            mediaRequestFileSelected={this.state.mediaRequestFileSelected}
            onSort={this.handleColumnSort}
            checkBoxAll={this.checkBoxAll()}
            onSelectLearner={this.onSelectLearner}
            selectedLearners= {this.state.selectedLearners}
            selectedLearnerName = {this.state.selectedLearnerName}
            selectedLearnerCode ={this.state.selectedLearnerCode}
            onShowAddLearnerErrorClose = {this.onShowAddLearnerErrorClose}
            showAddLearnerError={this.props.showAddLearnerError}
            onSelectCheckAll={this.onSelectCheckAll}
            closeLearnerModalHandler={this.closeLearnerModalHandler}
          />
        </PearsonElementsSdk.Modal>

        <PearsonElementsSdk.Modal
          isShown={this.state.showUBFRemoveFileModal}
          text={confirmUBFRemoveFiletext}
          footerVisible={false}
          cancelBtnHandler={this.cancelUBFRemoveFileModalHandler}
          successBtnHandler={this.successUBFRemoveFileModalHandler}
        >
          <ConfirmationModalGeneric 
            cancelBtnHandler={this.cancelUBFRemoveFileModalHandler}
            successBtnHandler={this.successUBFRemoveFileModalHandler} 
            conmfirmMsgRender = {this.getUBFRemoveFileConfirmationMsg}
          />
        </PearsonElementsSdk.Modal>
        <PearsonElementsSdk.Modal
          isShown={this.state.islastLinkedLearner}
          text={lastLearnerErrorText}
          footerVisible={false}
          cancelBtnHandler={this.cancellastLinkedLearnerModalHandler}
          successBtnHandler={this.successlastLinkedLearnerModalHandler}
        >
          <LastLearnerConfirmationModal
          
            cancelBtnHandler={this.cancellastLinkedLearnerModalHandler}
            successBtnHandler={this.successlastLinkedLearnerModalHandler} 
            conmfirmMsgRender = {this.getlastLinkedLearnerConfirmationMsg}
          />
        </PearsonElementsSdk.Modal>
        

        <SuccessAlert
          showAlert={submissionSuccess}
          alertTitle="The submitted sample request will be security and virus checked. If it passes these checks, it will be submitted for assessment. If it fails these checks, it will not be submitted."
          onClose={this.onSubmissionSuccessAlertClose}
        >
          <span>&nbsp;</span>
        </SuccessAlert>
        <ErrorAlert
          showAlert={submissionError}
          alertTitle="The sample set has not been submitted."
          onClose={this.onSubmissionErrorAlertClose}
        >
          <span>
            Please try again in a few minutes. If this problem persists contact{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={Config.HELP_LINKS.HELP}
            >
              customer service
            </a>
            .
          </span>
        </ErrorAlert>
      </div>
    );
  }

  isConsortia():boolean{
    if (this.props.sampleMediaRequest && this.props.sampleMediaRequest.consortiaId ){
      return true;
    } else{
      return false;
    }
  }
  // isCheckedAll():boolean{
  //   const mediaRequestFileSelected = this.state.mediaRequestFileSelected
  //   let selectedLearnerIds = new Array();
  //   const units = this.props.sampleMediaRequest && this.props.sampleMediaRequest.learnerUnits;
  //   const filteredUnits =
  //   units &&
  //   mediaRequestFileSelected ?
  //   units.filter(unit => unit.unitId === mediaRequestFileSelected.unitId) : "";
  //   const files = this.props.sampleMediaRequest && this.props.sampleMediaRequest.files.filter((file) => {
  //       if(file.fileId === (this.state.mediaRequestFileSelected && this.state.mediaRequestFileSelected.fileId)){
  //         selectedLearnerIds = file && file.learnerIds; 
  //       }
  //     })
  //   if ((files && !files.length && selectedLearnerIds.length) === filteredUnits.length) {
  //     return true;
  //   } else{
  //     return false;
  //   }
  // }

  

  componentWillReceiveProps(nextProps: Props) {
    const uploadsInProgress: number = nextProps.mediaUploadStatus
      ? nextProps.mediaUploadStatus.length
      : 0;
    this.setState({ uploadsInProgress });
    if (nextProps.sampleMediaRequest) {
      if (nextProps.sampleMediaRequest.status === "REQUEST_RECALLED") {
        const showRecallAlertModal = true;
        this.setState({ showRecallAlertModal });
        localStorage.setItem("cancelUpload", "true");
      }
    }
    
  }

  cancellastLinkedLearnerModalHandler(){
    this.setState({ islastLinkedLearner: false,showModal:true })
  }

  successlastLinkedLearnerModalHandler(){
  
    const {selectedFile , selectedLearnerUnit} =  this.state
    this.props.removeFileFromLearner(
      this.props.sampleMediaRequest as MediaRequest,
      selectedFile!,
      selectedLearnerUnit!
    ).then(()=>
    this.setState({ islastLinkedLearner: false,showModal:true })
     )
   
  }

  getlastLinkedLearnerConfirmationMsg(){
    return (<div>
      <p>
      This file was upload via the Upload by File functionality and this learner is the last learner linked to the file.  If this file is removed, it will also be removed from the request.
      </p>
      <span>Do you want to proceed?</span>
    </div>);
  }


  cancelModalHandler() {
    this.setState({ showModal: false });
  }

  successModalHandler() {
    this.setState({ showModal: false });
    toastr.success("Success!!");
  }

  cancelLearnerModalHandler() {
    this.onShowAddLearnerErrorClose()
    this.setState({ showLearnerModal: false });
  }

  successLearnerModalHandler() {
    this.setState({ showLearnerModal: false });
  }

  /*****UBF Upload By File************ */
  onUBFRemoveFile(
    removeSelectedFile: MediaRequestFile,
  ){ 
    // event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    this.setState({ 
      showUBFRemoveFileModal: true,
      mediaRequestFileSelected: removeSelectedFile
    });
    // event.preventDefault();
  }
  cancelUBFRemoveFileModalHandler() {
    this.setState({ showUBFRemoveFileModal: false });
  }

  successUBFRemoveFileModalHandler() {
    this.handleUBFRemoveFile();  
    this.setState({ showUBFRemoveFileModal: false });
  }

  handleUBFRemoveFile() {
    // const doNothing:any = () => void
    // doNothing();
    const learningProviderId = this.props.sampleMediaRequest!
      .learningProviderId;
    const mediaSetId = this.props.sampleMediaRequest!.mediaSetId;
    this.props.removeFile(learningProviderId, mediaSetId, this.state.mediaRequestFileSelected!.fileId);
  }
  getUBFRemoveFileConfirmationMsg() {
      return (<div>
        <p>
        This file will be removed from the request.  
        If learners have been linked to this file, it will no longer be available as part of their upload.
        </p>
        <span>Do you want to proceed?</span>
      </div>);
  }
  /******************************************* */
  closeLearnerModalHandler() {
    this.onShowAddLearnerErrorClose();
    this.setState({ showLearnerModal: false });
  
    // this.props.history.push(`/sample-requests`);
  }

  checkBoxAll() {
    const mediaRequestFileSelected = this.state.mediaRequestFileSelected
    // const selectedLearnerIds = this.state.mediaRequestFileSelected ? this.state.mediaRequestFileSelected.learnerIds : "";
    let selectedLearnerIds = new Array();
    const units = this.props.sampleMediaRequest && this.props.sampleMediaRequest.learnerUnits;
    const filteredUnits =
    units &&
    mediaRequestFileSelected ?
    units.filter(unit => unit.unitId === mediaRequestFileSelected.unitId) : "";
    const files = this.props.sampleMediaRequest && this.props.sampleMediaRequest.files.filter((file) => {
        if(file.fileId === (this.state.mediaRequestFileSelected && this.state.mediaRequestFileSelected.fileId)){
          selectedLearnerIds = file && file.learnerIds; 
        }
      })
      if((files && !files.length && selectedLearnerIds.length) === filteredUnits.length){
        return true;
      }else{
        return false
      }
      // const isCheckedAll =  ((files && !files.length && selectedLearnerIds.length) === filteredUnits.length) ? true : false;
      // return isCheckedAll;
  }

  onSelectCheckAll = (sortedfilteredUnits: any,  sampleMediaRequest: MediaRequest, mediaRequestFileSelected: MediaRequestFile, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const learnerIds = new Array();
    const learners = new Array();
    let isChecked =  false;

    sortedfilteredUnits.map((learner : LearnerUnit) => {
      const mediaRequestLearnerFiles=sampleMediaRequest.files.filter(
        (f: MediaRequestFile) =>
            (f.learnerIds.filter((l: string) =>  l === learner.learnerId)
                .length > 0) && 
                f.isUploadByFile === false &&
                (f.unitId === mediaRequestFileSelected.unitId)
                && (f.fileName === mediaRequestFileSelected.fileName));
        if(mediaRequestLearnerFiles.length > 0)
        {
          learners.push(learner)
        }
      learnerIds.push(learner.learnerId)
    })
    
    if((learnerIds.length === mediaRequestFileSelected.learnerIds.length)){
      isChecked =  true;
    }
    if(!isChecked && learners.length >0)
    {
      this.setState({selectedLearners:learners, showAddLearnerError: true})
      this.props.showAddLearnerErrorModal();
      return;
    }
    else
    {
      this.setState({showAddLearnerError: false})
      this.props.closeAddLearnerErrorModal();
    }
    // isChecked =  this.isChecked(learnerIds.length,mlearnIdsLen); // (learnerIds.length === mediaRequestFileSelected.learnerIds.length) ? true : false
    const action = isChecked ? "remove-learner" : "add-learner";
    this.props.updateLearners(learnerIds, sampleMediaRequest, action, mediaRequestFileSelected)
  }
  onSelectLearner = (mediaRequestFiles: LearnerUnit,
    sampleMediaRequest: MediaRequest,
    mediaRequestFileSelected: MediaRequestFile,
    isChecked: boolean,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
    const selectedLearnerName = mediaRequestFiles.firstName +" " +mediaRequestFiles.surname;
    if(!isChecked)
    {
        const mediaRequestLearnerFiles=sampleMediaRequest.files.filter(
                                            (f: MediaRequestFile) =>
                                                (f.learnerIds.filter((l: string) => l === mediaRequestFiles.learnerId)
                                                    .length > 0) && (f.unitId === mediaRequestFiles.unitId)
                                                    && (f.fileName === mediaRequestFileSelected.fileName)
                                          );
        if(mediaRequestLearnerFiles.length > 0)
        {
           this.setState({selectedLearners:[], selectedLearnerCode:mediaRequestFiles.learnerCode, selectedLearnerName, showAddLearnerError: true})
           this.props.showAddLearnerErrorModal();
           return;
        }
        else
        {
          this.setState({selectedLearners:[], selectedLearnerCode:mediaRequestFiles.learnerCode, selectedLearnerName, showAddLearnerError: false})
          this.props.closeAddLearnerErrorModal();
        }
    }

    const action = isChecked ? "remove-learner" : "add-learner";
    this.props.updateLearners(mediaRequestFiles, sampleMediaRequest, action, mediaRequestFileSelected)
  }

  showLearners(
    showselectedLearner: MediaRequestFile,
  ) {
    this.setState({
      showLearnerModal: true,
      mediaRequestFileSelected: showselectedLearner
    });
  }

  filterFilesForLearner = (
    files: MediaRequestFile[],
    learnerUnit: LearnerUnit
  ) => {
    const filteredFiles: Array<
      MediaRequestFile | undefined
    > = learnerUnit.fileIds.map(luf => _.find(files, f => f.fileId === luf));
    // .filter(files, (f) => learnerUnit.fileIds.includes(f.fileId));

    const filteredFilesWithBytes = filteredFiles.map(elem => {
      return elem && elem.fileSize
        ? { ...elem, fileSizeBytes: this.sizeInBytes(elem.fileSize) }
        : { ...elem, fileSizeBytes: 0 };
    });
    return filteredFilesWithBytes.filter(
      x => x !== undefined
    ) as MediaRequestFile[];
  };

  sizeInBytes = (filesizeStr: string) => {
    const str = filesizeStr;
    if (!str) {
      return 0;
    }
    const sizeStr = str.replace(/[0-9\. ]/g, "").toUpperCase();
    const size = parseFloat(str.replace(/[a-zA-Z ]/g, ""));
    let fileSizeNum = 0;
    switch (sizeStr) {
      case "BYTES":
        fileSizeNum = size;
        break;
      case "KB":
        fileSizeNum = Math.floor(size * Math.pow(1024, 1));
        break;
      case "MB":
        fileSizeNum = Math.floor(size * Math.pow(1024, 2));
        break;
      case "GB":
        fileSizeNum = Math.floor(size * Math.pow(1024, 3));
        break;
    }
    return fileSizeNum;
  };

  showFiles(
    learnerUnit: LearnerUnit,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    const userType =
      this.props.auth && this.props.auth.profile
        ? this.props.auth.profile.family_name
        : "";
    this.setState({ isBusy: true });
    if (
      userType.toUpperCase() === userTypeEnums.EXTERNAL_USER.toUpperCase() ||
      localStorage.getItem("proxyName") === ""
    ) {
      this.props
        .getSampleMediaRequests(
          this.props.sampleMediaRequest!.learningProviderId
        )
        .then(() => {
          this.setState({ isBusy: false });
        });
    } else {
      // if(localStorage.getItem('proxyName') === ''){
      //     this.props.getSampleMediaRequests(this.props.sampleMediaRequest!.learningProviderId)
      //   .then(() => {
      //     this.setState({ isBusy: false });
      //   })
      // }else{
      this.props
        .getMediaSetsByLearningProviderCode(
          this.props.sampleMediaRequest!.learningProviderCode
        )
        .then(() => {
          this.setState({ isBusy: false });
        });
      // }
    }

    // if(localStorage.getItem('proxyName') !== '' && localStorage.getItem('userType') !== 'EXT'){

    // }
    this.setState({ showModal: true, selectedLearnerUnitCombo: learnerUnit });
    event.preventDefault();
  }

  onSubmissionSuccessAlertClose() {
    return this.setState({ submissionSuccess: false });
  }

  onShowAddLearnerErrorClose() {
    this.props.closeAddLearnerErrorModal();
  }

  onSubmissionErrorAlertClose() {
    return this.setState({ submissionError: false });
  }

  uploadFileEvent(file: File[], unitId: string, learnerId: string) {
    const learningProviderId = this.props.sampleMediaRequest!
      .learningProviderId;
    const mediaSetId = this.props.sampleMediaRequest!.mediaSetId;
    const mediaUploadFiles: MediaUploadFile[] = new Array<MediaUploadFile>();

    file.forEach(fileElement => {
      if (fileElement.size / 1024 / 1024 > Config.MEDIA_FILES.MAX_FILE_SIZE) {
        const fileTooLargeMessage = new GlobalAlertMessage();
        fileTooLargeMessage.title = fileElement.name;
        fileTooLargeMessage.message =
          "File is too large, only files " +
          (Config.MEDIA_FILES.MAX_FILE_SIZE / 1024).toFixed(1) +
          "GB or smaller are permitted!";
        this.props.raiseAlert(fileTooLargeMessage);
      } else {
        const mediaUploadFile: MediaUploadFile = {
          file: fileElement,
          learningProviderId,
          mediaSetId,
          unitId,
          learnerId
        };
        mediaUploadFiles.push(mediaUploadFile);
      }
    });

    const uploads: Array<Promise<void>> = mediaUploadFiles.map(
      mediaFile =>
        new Promise<void>((resolve, reject) => this.props.upload(mediaFile))
    );

    Promise.all(uploads).then();

    return;
  }

  getIconName = (selectedColumn: string) => {
    if (this.state.selectedColumn === selectedColumn) {
      return this.state.direction === "up" ? "sort-up-18" : "sort-down-18";
    }

    return "sortable-18";
  };

  handleColumnSort = (selectedColumn: string) => {
     return () => {
      let direction = "down";

      if (this.state.selectedColumn === selectedColumn) {
        direction = this.state.direction === "up" ? "down" : "up";
      }
      const onLoadSort = false;

      this.setState({ selectedColumn, direction, onLoadSort });
    };
  };

  sort(learners: LearnerUnit[]): LearnerUnit[] {
    const lodashDirection = this.state.direction === "down" ? "desc" : "asc";

    const sortedlearners = _.orderBy(
      learners,
      [
        learner => {
          if (this.state.selectedColumn === "learnerName") {
            return `${learner.firstName} ${learner.surname}`;
          } else if (
            typeof learner[this.state.selectedColumn] + "".toLowerCase ===
            "function"
          ) {
            return learner[this.state.selectedColumn].toLowerCase();
          } else if (this.state.selectedColumn === "fileIds") {
            return learner[this.state.selectedColumn].length
              .toString()
              .toLowerCase();
          } else {
            return learner[this.state.selectedColumn] + "";
          }
        }
      ],
      [lodashDirection]
    ) as LearnerUnit[];

    return this.filterLearnersByUnit(
      sortedlearners,
      this.state.selectedUnit.value
    ); // sortedlearners;
  }

  goBack(event: Event) {
    event.preventDefault();
    this.props.history.push(`/sample-requests`);
  }

  submit(event: Event) {
    this.setState({ showSubmitModal: true });
  }

  canSubmit(): boolean {
    if (
      this.props.sampleMediaRequest &&
      this.props.sampleMediaRequest.learnerUnits
    ) {
      const sampleMediaRequestStatus = [
        "UPLOAD_IN_PROGRESS",
        "UPLOAD_NOT_SUBMITTED",
        "UPLOAD_RETURNED"
      ];
      if (
        !sampleMediaRequestStatus.includes(this.props.sampleMediaRequest.status)
      ) {
        return false;
      }
      // if (this.props.sampleMediaRequest.status !== "UPLOAD_IN_PROGRESS" ) {
      //   return false;
      // }else if(this.props.sampleMediaRequest.status !== ""){
      //   return false;
      // }

      const learnerUnitsWithNoFiles = this.props.sampleMediaRequest.learnerUnits.filter(
        (l: LearnerUnit) => l.fileIds.length === 0
      );
      if (learnerUnitsWithNoFiles.length > 0) {
        return false;
      }
      
      const filesWithNoLearner = this.props.sampleMediaRequest.files.filter(
        (l: MediaRequestFile) => {
          return l.learnerIds.length === 0;
        }
      );
     
      if (filesWithNoLearner.length > 0) {
        return false;
      }

      const submitEnableStatus = ["READY", "PREPARING"];
      for (const file of this.props.sampleMediaRequest.files) {
        if (!submitEnableStatus.includes(file.fileStatus)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  cancelSubmitModalHandler() {
    this.setState({ showSubmitModal: false });
  }

  closeRecallAlertModalHandler() {
    this.setState({ showRecallAlertModal: false });
    this.props.history.push(`/sample-requests`);
  }

  successSubmitModalHandler() {
    if (this.props.sampleMediaRequest) {
      this.props
        .submitMediaRequest(
          this.props.sampleMediaRequest,
          `${this.props.auth.profile.identities[0].userId.split('.').join(" ")}`
        )
        .then(() => {
          this.setState({ submissionSuccess: true });
        })
        .catch(() => {
          this.setState({ submissionError: true });
        });

      this.setState({ showSubmitModal: false });
    }
  }

  onSelectUnit(event: any) {
    const unitSelected = event.target.value;
    const unit = {item: unitSelected , value: unitSelected};
    this.setState({
      selectedUnit: unit
    });
  }

  filterLearnersByUnit(learners: LearnerUnit[], unit: string) {
    if (unit === "0") {
      return learners;
    }

    return learners.filter((l: LearnerUnit) => l.unitName === unit);
  }

  getUnits(learners: LearnerUnit[]) {
    const units = learners && learners.map(learner => ({
      unitId: learner.unitId,
      unitName: learner.unitName
    }));

    if (units && units.length) {
      return _.uniqBy(units, x => x.unitId + " " + x.unitName);
    }
    return [];
  }

  onRemoveFile(
    file: MediaRequestFile,
    learnerUnit: LearnerUnit,
    event: React.UIEvent<HTMLSpanElement>
  ) {
    event.preventDefault();
if(file.learnerIds.length === 1 && file.isUploadByFile){
return this.setState({ islastLinkedLearner : true , selectedFile: file , selectedLearnerUnit: learnerUnit,showModal:false})
}

  else{this.props.removeFileFromLearner(
      this.props.sampleMediaRequest as MediaRequest,
      file,
      learnerUnit
    );
  }
  }

  refreshMediaRequest() {
    let refreshRequired: boolean = false;

    if (this.props.sampleMediaRequest) {
      // if(this.props.sampleMediaRequest.status === 'REQUEST_RECALLED'){
      //   // clearInterval(this.state.refreshTimer);
      //   // refreshRequired = false;
      //   const showRecallAlertModal = true
      //   this.setState({showRecallAlertModal});
      // }
      const sampleMediaRequestStatus = [
        "UPLOAD_IN_PROGRESS",
        "AWAITING_UPLOAD",
        "REQUEST_RECALLED"
      ];
      if (
        sampleMediaRequestStatus.includes(this.props.sampleMediaRequest.status)
      ) {
        // this.props.sampleMediaRequest.status == ''
        // this.props.sampleMediaRequest.files.forEach((file) => {
        // Currently the file being in a 'PREPARING' status is the only variable that triggers the need
        // for an refresh of the sample media request.
        // if (file.fileStatus === 'PREPARING') {
        refreshRequired = true;
      }
      // });

      if (refreshRequired) {
        this.props.refreshSampleMediaRequest(this.props.sampleMediaRequest);
      }
    }
  }
}

export default SampleMediaRequestsLearners;
