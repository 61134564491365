import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import loading from './loading.svg';

export interface DispatchProps {
  handleAuthentication: (callback: (err: string) => void) => void;
  callbackInProgress: () => void;
  signoutUser: () => void;
  callbackComplete: () => void;
}

export interface CallbackProps {
  loading: boolean,
  error: string
}

type Props = CallbackProps & DispatchProps & RouteComponentProps<{}>;

export class Callback extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  componentWillMount() {
    this.props.callbackInProgress();
    this.forceUpdate();
  }

  shouldComponentUpdate(nextProps: Props) {
    return this.props.loading === false;
  }

  componentWillUpdate(nextProps: Props) {

    this.props.handleAuthentication(err => {
      if (err) {
        this.props.history.push("/");
      }
      this.props.callbackComplete();
      return this.props.history.push('/Home');
    })

  }

  render() {
    const style = {
      backgroundColor: 'white',
      bottom: 0,
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      left: 0,
      position: 'absolute' as 'absolute',
      right: 0,
      top: 0,
      width: '100vw'
    };

    return (
      <div style={style}>
        <img src={loading} alt="loading"  style={{width:"142px"}}/>
      </div>
    );
  }
}

export default Callback;
