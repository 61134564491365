interface Config {
  APP_NAME: string;
  API_GATEWAY: { URL: string };
  ORIGIN: string;  
  FORGEROCK: { TIMEOUT: number, AUTHORIZE_ENDPOINT: string, SESSION_END_ENDPOINT: string, REDIRECT_URI: string, SCOPES: string[], CLIENT_ID: string, TOKEN_ENDPOINT: string};
  MEDIA_FILES : { MAX_FILE_SIZE: number, UPLOAD_RESUME_MAX_HOURS: number };
  IS_MOCK: {MOCK_USERS: boolean, MOCK_FR: boolean, MOCK_USERS_INTERNAL:boolean};
  SALT: string; // This salt is harcoded in src/server/routes/permissions.ts
  HELP_LINKS:{HELP:string, HELP_INTERNAL:string, TERMS_OF_USE:string, PRIVACY_POLICY: string};
  FOOTER_LINKS:{name:string,href:string}[];
  refreshTime: number;
  refreshTime2: number;
  refreshTime3: number;
}

const dev: Config = {
  API_GATEWAY: {
    URL: 'http://localhost:3001'
  },
  ORIGIN: "http://localhost:3000",
  APP_NAME: 'Learner Media',
  FORGEROCK: {
		CLIENT_ID: 'ranevag3f3qang2ff5apspppo',
		TIMEOUT: 3600000,
    SCOPES: ['openid', 'profile', 'email'],
    AUTHORIZE_ENDPOINT: 'https://pearson-shared-acc-dev1.auth.eu-west-2.amazoncognito.com/oauth2/authorize',
    TOKEN_ENDPOINT: 'https://pearson-shared-acc-dev1.auth.eu-west-2.amazoncognito.com/oauth2/token',
    REDIRECT_URI: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/callback`,
    SESSION_END_ENDPOINT: 'https://pearson-shared-acc-dev1.auth.eu-west-2.amazoncognito.com/logout',
  },
  MEDIA_FILES : {
    MAX_FILE_SIZE: 8192,
    UPLOAD_RESUME_MAX_HOURS: 336 // 14 days to match S3 bucket clean-up
  },
  HELP_LINKS:{
    HELP: 'https://support.pearson.com/uk/s/qualification-contactus',
    HELP_INTERNAL: 'https://pearsonnow.service-now.com/esc',
    TERMS_OF_USE: 'https://www.pearson.com/uk/legal-notice.html',
    PRIVACY_POLICY: 'https://qualifications.pearson.com/en/privacy-policy.html'      
  },
  IS_MOCK: {
    MOCK_FR: false, 
    MOCK_USERS: false,
    MOCK_USERS_INTERNAL: false,
  },
  SALT: 'd3O0w9b1l6sd6F3Efeq',
  FOOTER_LINKS :[
    {
      name: "Terms of Use",
      href: 'https://www.pearson.com/en-gb/legal-information/terms-of-use.html'
    },
    {
      name: "Privacy Policy",
      href: 'https://qualifications.pearson.com/en/privacy-policy.html' 
    },
    
  ],
  refreshTime:60000,
  refreshTime2:30000,
  refreshTime3:15000
};

// const prod: Config = {
//   API_GATEWAY: {
//     URL: '**ENVIRONMENT_SERVER_URL**'
//   },
//   APP_NAME: 'Learner Media',
//   ORIGIN:`${window.location.protocol}//${window.location.hostname}`,
//   FORGEROCK: {
// 		CLIENT_ID: '**COGNITO_APP_CLIENT_ID**',
// 		TIMEOUT: 3600000,
//     SCOPES: ['openid', 'profile', 'email'],
//     REDIRECT_URI: `${window.location.origin}/callback`,
//     AUTHORIZE_ENDPOINT: '**COGNITO_AUTH_BASE_URL**/oauth2/authorize',
//     SESSION_END_ENDPOINT: '**COGNITO_AUTH_BASE_URL**/logout',
//     TOKEN_ENDPOINT: '**COGNITO_AUTH_BASE_URL**/oauth2/token',
//   },
//   MEDIA_FILES : {
//     MAX_FILE_SIZE: 8192
//   },
//   HELP_LINKS : {
//     HELP: 'https://qualifications.pearson.com/en/contact-us.html',
//     HELP_INTERNAL: 'https://pearsonnow.service-now.com/esc',
//     TERMS_OF_USE: 'https://www.pearson.com/uk/legal-notice.html',
//     PRIVACY_POLICY: 'https://qualifications.pearson.com/en/privacy-policy.html'
//   },
//   IS_MOCK: {
//     MOCK_FR: false, 
//     MOCK_USERS: false,
//     MOCK_USERS_INTERNAL: false,
//   },
// };

const prod: Config = {
  API_GATEWAY: {
    URL: '**ENVIRONMENT_SERVER_URL**'
  },
  APP_NAME: 'Learner Media',
  ORIGIN:`${window.location.protocol}//${window.location.hostname}`,
  FORGEROCK: {
 		TIMEOUT: 3600000,
    CLIENT_ID: '**COGNITO_APP_CLIENT_ID**', // need to fetch from AWS - SSM
    SCOPES: ['openid', 'profile', 'email'],
    REDIRECT_URI: `${window.location.origin}/callback`,
    AUTHORIZE_ENDPOINT: '**COGNITO_AUTH_BASE_URL**/oauth2/authorize',
    SESSION_END_ENDPOINT: '**COGNITO_AUTH_BASE_URL**/logout',
    TOKEN_ENDPOINT: '**COGNITO_AUTH_BASE_URL**/oauth2/token',
  },
  MEDIA_FILES : {
    MAX_FILE_SIZE: 8192,
    UPLOAD_RESUME_MAX_HOURS: 336 // 14 days to match S3 bucket clean-up
  },
  IS_MOCK: {
    MOCK_FR: false,
    MOCK_USERS: false,
    MOCK_USERS_INTERNAL: false,
  },
  SALT: 'd3O0w9b1l6sd6F3Efeq',
  HELP_LINKS:{
    HELP: 'https://qualifications.pearson.com/en/contact-us.html',
    HELP_INTERNAL: 'https://pearsonnow.service-now.com/esc',
    TERMS_OF_USE: 'https://www.pearson.com/uk/legal-notice.html',
    PRIVACY_POLICY: 'https://qualifications.pearson.com/en/privacy-policy.html'      
  },
  FOOTER_LINKS :[
    {
      name: "Terms of Use",
      href: 'https://www.pearson.com/en-gb/legal-information/terms-of-use.html'
    },
    {
      name: "Privacy Policy",
      href: 'https://qualifications.pearson.com/en/privacy-policy.html' 
    },
    
  ],
  refreshTime:60000,
  refreshTime2:30000,
  refreshTime3:15000
};

// @ts-ignore
const config: Config = process.env.NODE_ENV === 'production' ? prod : dev;

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};