import * as React from "react";

import { LearnerUnit } from "models/LearnerUnit";
import {
  MediaRequest,
  MediaRequestFile
} from "../../../../models/media-request";

import { Checkbox } from "ukas.uux.react-components";

interface SampleMediaRequestListRowProps {
  mediaRequestFiles: LearnerUnit;
  sampleMediaRequest: MediaRequest | null;
  onSelectLearner:(mediaRequestFiles:LearnerUnit, sampleMediaRequest: MediaRequest, mediaRequestFileSelected: MediaRequestFile, isChecked : boolean, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  isConsortia:number| null;
  mediaRequestFileSelected: MediaRequestFile | null;
  selectedLearnersList : any;
  mediaRequestStatus: boolean;

}

const SampleMediaRequestListRow = ({
  mediaRequestFiles,
  isConsortia,
  onSelectLearner,
  sampleMediaRequest,
  mediaRequestFileSelected,
  selectedLearnersList,
  mediaRequestStatus

}: SampleMediaRequestListRowProps) => {
  const isChecked = selectedLearnersList && selectedLearnersList[0].learnerIds.includes(mediaRequestFiles.learnerId);
  
  return (
    <tr onClick={onSelectLearner.bind(event, mediaRequestFiles, sampleMediaRequest, mediaRequestFileSelected, isChecked)}>
      <td>{mediaRequestFiles.learningProviderCode}&nbsp; {mediaRequestFiles.learningProviderName}</td>
   
     
      <td>{mediaRequestFiles.learnerCode}</td>
      <td>{mediaRequestFiles.firstName + " " + mediaRequestFiles.surname}</td>
      <td>
        {
          !mediaRequestStatus && 
          <div>
            <div className="pe-checkbox">
            <Checkbox
              checked={isChecked}
              id={mediaRequestFiles.learnerId}
              aria-label="this is an aria-label"
            />
            </div>
          </div>
        }
      </td>
    </tr>
  );
};

export default SampleMediaRequestListRow;
