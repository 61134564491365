import * as React from 'react';

import {Button,Select} from 'ukas.uux.react-components';
import * as _ from 'lodash';
import { MediaRequestFile } from '../../../../models/media-request';
import { LearnerUnit } from '../../../../models/LearnerUnit';
import { MediaRequest } from '../../../../models/media-request'
import FilesTab from './sample-media-request-files-tab';
import LearnersTab from './sample-media-request-learners-tab';

interface Props {
  sampleMediaRequestLearners: LearnerUnit[];
  mediaRequestFiles: MediaRequestFile[];
  units: Array<{ unitId: string; unitName: string }>;
   onSelectUnit: (event: any) => void;
  onUBFRemoveFile: (
    removeSelectedFile: MediaRequestFile,
  ) => void;
  goBack: (event: any) => void;
  canSubmit: () => boolean;
  submit: (event: any) => void;
  allowUpload: boolean;
  showFiles: (
    learnerUnit: LearnerUnit,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  uploadFileEvent: (file: File[], unitId: string, learnerId: string) => void;
  unitFilterLabel: string;
  onSort: (item: string) => void;
  getIconName: (columnName: string) => string;
  onLoadSort: boolean;
  isConsortia: boolean;
  sampleMediaRequest: MediaRequest;
 showLearners: (
  showLearner: MediaRequestFile,
   ) 
   => void;
}
interface State {
  learnersTab: boolean;
  filesTab: boolean,

}
export class SampleMediaRequestsLearnersList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      learnersTab: true,
      filesTab: false

    }
    this.handleFileTab = this.handleFileTab.bind(this);
    this.handleLearnerTab = this.handleLearnerTab.bind(this);
  }
  handleFileTab = () => {
    this.setState({filesTab : true, learnersTab : false})
  }
  handleLearnerTab = () => {
    this.setState({filesTab : false, learnersTab: true})
  }
  render() {
    const {
      //  sampleMediaRequestLearners,
      // mediaRequestFiles,
      units,
      onSelectUnit,
      canSubmit,
      submit,
      goBack,
      // showFiles,
      // uploadFileEvent,
      unitFilterLabel,
      // allowUpload,
      // onSort,
      // getIconName,
      // onLoadSort,
      // isConsortia
    } = this.props;

    const fileTabActive = this.state.filesTab ? "activeTab tabButtons" : "tabButtons";
    const learnerTabActive = this.state.learnersTab ? "activeTab tabButtons" : "tabButtons";
    return (
      <div>
        <div className="pe-table-toolbar">
          <div className="pull-left">
            {' '}
            <Select
              title={unitFilterLabel}
              id="unitsFilterButton"
              label="Filter by unit"
              onChange={onSelectUnit}        
            >
          
               <option
              value="0"
              label="All Units"
          
            />
               {units.map(unit => (
              <option
                key={unit.unitId}
                id={unit.unitId}
                value={unit.unitName}
                label={unit.unitId + ' ' + unit.unitName}
              
              />
            ))}
            </Select>
          </div>
          <div className="pull-right">
            <div className="pe-btngroup">
              {/* <PearsonElementsSDK.Button btnType="primary">
                <PearsonElementsSDK.Icon name="person-18" />
              </PearsonElementsSDK.Button>
              <PearsonElementsSDK.Button>
                <PearsonElementsSDK.Icon name="file-18" />
              </PearsonElementsSDK.Button> */}
             <span className="pe-label pe-label--bold"> Upload by</span> :
               <span style = {{cursor:"pointer"}}>
                  <span className = {learnerTabActive} onClick = {this.handleLearnerTab} > Learner </span> | 
                  <span className = {fileTabActive} onClick = {this.handleFileTab} > File </span>
              </span>
            </div>
          </div>

          <div className="clearfix" />
        </div>

        {/* <PearsonElementsSDK.Tabs bar={false}>
          <PearsonElementsSDK.Pane label="Tabby">
            <div>Tab 1</div>
          </PearsonElementsSDK.Pane> 
          <PearsonElementsSDK.Pane label="Tabetha">
            <div>Tab 2</div>
          </PearsonElementsSDK.Pane>
           <PearsonElementsSDK.Pane label="Tabberino">
            <div>Tab 3</div>
          </PearsonElementsSDK.Pane>
        </PearsonElementsSDK.Tabs> */}
        {
          this.state.learnersTab && <LearnersTab {...this.props} />
        }
        {
          this.state.filesTab && <FilesTab {...this.props}/>
        }       

        <Button onClick={(e)=>goBack(e)}>
          Back
        </Button>
        <div className="pull-right">
          <Button onClick={(e)=>submit(e)} variant="primary" disabled={canSubmit() ? false : true} >
            Submit
          </Button>
        </div>
      </div>
    );
  };
}

export default SampleMediaRequestsLearnersList;
