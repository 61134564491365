import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Route, RouteComponentProps, RouteProps, Redirect, Switch } from 'react-router';
import withBreadcrumbs, {
  BreadcrumbsRoute,
  InjectedProps
} from 'react-router-breadcrumbs-hoc';

import CreateMediaSet from './create-media-set/create-media-set.container';
import Home from './home/home';
import NotFound from './not-found/not-found';
import Maintain from '../components/common/maintain/maintain'

import Availability from './assessment-associate-availability/assessment-associate-availability.container';
import SampleMediaRequests from '../features/sample-media-request/sample-media-request-list/sample-media-request-list.container.component';
import SampleMediaRequestLearners from '../features/sample-media-request/sample-media-request-learners/sample-media-request-learners.container.component';
import Callback from './callback/callback.container';
import AuthForgeRock from '../actions/auth/auth';
import breadCrumbImage from "../breadcrumb-18.svg";

// import { OwnProps as learnersBreadcrumbProps} from '../features/sample-media-request/sample-media-request-learners/sample-media-request-learners.component';

const auth = new AuthForgeRock();

// const LearnerBreadcrumb = ({ match }: learnersBreadcrumbProps) => (
//   <span>Sample Media Request - {match.params.sampleMediaRequestId}</span>
// ); // use match param userId to fetch/display user name

const routes: BreadcrumbsRoute[] = [
  { path: '/not-found', breadcrumb: '404' },
  // { path: '/maintenance', breadcrumb: '' },
  { path: '/Availability', breadcrumb: 'AA Availability' },
  { path: '/sample-requests', breadcrumb: 'Sample Requests' },
  // { path: '/createMediaSet', breadcrumb: 'Create Media Set' },
  {
    path: '/sample-requests/:sampleMediaRequestId',
    breadcrumb: 'Learner Material Upload'
  }
];

// map & render your breadcrumb components however you want
const Breadcrumbs = ({ breadcrumbs }: InjectedProps) => (
  <div className="pe-template__single" style={{ paddingTop: 20 }}>
    {breadcrumbs.map(({ breadcrumb, match }, index: number) => (
      <span key={match.url}>
        {breadcrumbs.length - 1 !== index ? (
          <span key={match.url} className="breadcrumb">
            <NavLink
              to={match.url}
              className="pe-label pe-label--small"
            >
              {breadcrumb}
            </NavLink>
            <img src={breadCrumbImage} className="breadCrumbImage"/>
            {/* <PearsonElementsSDK.Icon name="breadcrumb-18" /> */}
          </span>
        ) : (
          <span className="pe-label--small pe-bold">{breadcrumb}</span>
        )}
      </span>
    ))}
  </div>
);

export const Routes = () => (
  <div>
    <Switch>
    <Route exact={true} path="/not-found" component={NotFound} />
    <Route exact={true} path="/maintenance" component={Maintain} />

    <Route exact={true} path="/" component={Home} />
    <PrivateRoute
      exact={true}
      path="/sample-requests"
      component={SampleMediaRequests}
    />
    <PrivateRoute
      exact={true}
      path="/sample-requests/:sampleMediaRequestId"
      component={SampleMediaRequestLearners}
    />
    <Route
        exact={true}
        path="/select-a-learning-provider"
        component={SampleMediaRequests}
      />
    <Route exact={true} path="/availability" component={Availability} />
    <Route exact={true} path="/callback" component={Callback} />
    <Route exact={true} path="/createMediaSet" component={CreateMediaSet} /> 
    <Route exact={true} path="/home">
      <Redirect to={{ pathname: '/' }} />
    </Route>
    <Redirect to="/not-found" /></Switch>
  </div>
);

export const x = withBreadcrumbs(routes)(Breadcrumbs);

const PrivateRoute = ({ component, ...rest }: RouteProps) => {
  if (!component) {
    throw Error('component is undefined');
  }

  const Component = component; // JSX Elements have to be uppercase.

  const render = (props: RouteComponentProps<{}>): React.ReactNode => {
    if (auth.isAuthenticated()) {
      return <Component {...props} />;
    }
    return <Redirect to={{ pathname: '/' }} />;
  };

  return <Route {...rest} render={render} />;
};

export default Routes;
export { x as breadcrumb };
