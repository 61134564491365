import * as React from "react";
import {
  MediaRequest,
  MediaRequestFile
} from "../../../../models/media-request";
import { LearnerUnit } from "models/LearnerUnit";
import SampleMediaRequestFilesListRow from "./sample-media-request-files-list-row";
import ErrorAlert from "../../../../components/common/error-alert";
import { useState } from "react";
import _ from "lodash";
import { requestStatusEnums as requestStatus } from '../../../sample-media-request/shared/enum';

import { Button, Table } from 'ukas.uux.react-components';
interface LearnerMediaFileList {
  sampleMediaRequest: MediaRequest | null;
  mediaRequestFileSelected: MediaRequestFile | null;
  isBusy: boolean;
  onSort: (item: string) => void;
  closeLearnerModalHandler: (e: any) => void;
  checkBoxAll: boolean;
  showAddLearnerError: boolean;
  selectedLearnerCode: string;
  selectedLearnerName: string;
  selectedLearners: LearnerUnit[] | null;
  onShowAddLearnerErrorClose: () => void;
  onSelectLearner: (mediaRequestFiles: LearnerUnit, sampleMediaRequest: MediaRequest, mediaRequestFileSelected: MediaRequestFile, isChecked: boolean, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onSelectCheckAll: (sortedfilteredUnits: any, sampleMediaRequest: MediaRequest, mediaRequestFileSelected: MediaRequestFile, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const LearnerMediaFileList = ({
  sampleMediaRequest,
  mediaRequestFileSelected,
  closeLearnerModalHandler,
  checkBoxAll,
  onSelectLearner,
  showAddLearnerError,
  onShowAddLearnerErrorClose,
  onSelectCheckAll,
  selectedLearnerName,
  selectedLearnerCode,
  selectedLearners

}: LearnerMediaFileList) => {
  require("./manage-upload-modal.css");
  // const selectedLearnersList = mediaRequestFileSelected ? mediaRequestFileSelected.learnerIds : ""

  const selectedLearnersList = sampleMediaRequest && sampleMediaRequest.files.filter(file => file.fileId === (mediaRequestFileSelected && mediaRequestFileSelected.fileId))

  const units = sampleMediaRequest && sampleMediaRequest.learnerUnits;
  const [selectedColumn] = useState<string>("fileName");
  const [direction] = useState<string>("up");
  const [searchText] = useState("");

  const filteredUnits =
    units &&
    mediaRequestFileSelected &&
    units.filter(unit => unit.unitId === mediaRequestFileSelected.unitId);

  const learners = selectedLearners && selectedLearners.map(({ learnerCode, firstName, surname }) => `${learnerCode} ${firstName} ${surname}`).join(', ');

  const sortedfilteredUnits =
    selectedColumn === ""
      ? filter(filteredUnits, searchText)
      : sort(filteredUnits, selectedColumn, direction);

  const isConsortia = sampleMediaRequest && sampleMediaRequest.consortiaId;

  // const consortiaErrorText = `A file with the same name has already been uploaded for ${selectedLearnerCode}   ${selectedLearnerName}. This learner cannot be linked to the file again.`;
  // const nonConsortiaErrorText = `A file with the same name has already been uploaded for ${sampleMediaRequest && sampleMediaRequest.learningProviderCode}   ${sampleMediaRequest && sampleMediaRequest.learningProviderName}. This learner cannot be linked to the file again.`
  const sampleMediaRequestStatus = sampleMediaRequest && sampleMediaRequest.status ? sampleMediaRequest.status : "";
  return (
    <div>
      {
        !checkRequestStatus(sampleMediaRequestStatus) ?
          <p>Select learner(s) to link to the uploaded file.</p> :
          <p>The listed learner(s) are linked to the selected file.</p>
      }
      {selectedLearners &&
        selectedLearners.length > 0 && (

          <ErrorAlert
            showAlert={showAddLearnerError}
            alertTitle={`A file with the same name has already been uploaded for ${learners}. This learner cannot be linked to the file again.`}
            onClose={onShowAddLearnerErrorClose}
          >
            <span>&nbsp;</span>
          </ErrorAlert>
        )}
      {selectedLearners === null ||
        selectedLearners.length === 0 && (
          <ErrorAlert
            showAlert={showAddLearnerError}
            alertTitle={`A file with the same name has already been uploaded for ${selectedLearnerCode}   ${selectedLearnerName}. This learner cannot be linked to the file again.`}
            onClose={onShowAddLearnerErrorClose}
          >
            <span>&nbsp;</span>
          </ErrorAlert>
        )}


      <div className="pe-template__single content">
        <section>
          <div>
            <div className="pe-row">
              <div className="pe-col-6">
                <div className="pe-label small-gap">
                  <span className="pe-label pe-label--bold">
                    Learning Provider
                  </span>
                  <br />
                  {(sampleMediaRequest && sampleMediaRequest.parentLearningProviderCode) ? sampleMediaRequest.parentLearningProviderCode : (sampleMediaRequest && sampleMediaRequest.learningProviderCode)}
                  &nbsp;
                  {sampleMediaRequest &&
                    sampleMediaRequest.learningProviderName}
                  &nbsp;
                </div>
              </div>
              <div className="pe-col-6">
                <div className="pe-label small-gap">
                  <span className="pe-label pe-label--bold">Qualification</span>
                  <br />
                  {sampleMediaRequest &&
                    sampleMediaRequest.qualificationGroupCode}
                  &nbsp;
                  {sampleMediaRequest && sampleMediaRequest.qualificationName}
                  &nbsp;
                  <b>
                    {isConsortia ? "(C)" : ""}
                  </b>
                  &nbsp;
                  <b className="second-sample-text-color">{sampleMediaRequest && sampleMediaRequest.parentRequests && sampleMediaRequest.parentRequests.length > 0 ? "(" + (sampleMediaRequest.parentRequests.length + 1) + ")" : ""}</b>
                </div>
              </div>
            </div>

            <div className="pe-row">
              <div className="pe-col-6">
                <div className="pe-label small-gap">
                  <span className="pe-label pe-label--bold">Unit</span>
                  <br />
                  {sampleMediaRequest &&
                    sampleMediaRequest.learnerUnits[0].unitName}
                </div>
              </div>
              <div className="pe-col-6">
                <div className="pe-label small-gap">
                  <span className="pe-label pe-label--bold">File Name</span>
                  <br />
                  {mediaRequestFileSelected &&
                    mediaRequestFileSelected.fileName}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <br />
      <div className="scrollTable">
        {" "}
        <Table responsive="xl">
          <thead>
            <tr>
              <td>Learning Provider</td>
              <td>Learner Code</td>
              <td>Learner</td>
              <th>
                {!checkRequestStatus(sampleMediaRequestStatus) &&
                  <div className="pe-checkbox" onClick={onSelectCheckAll.bind(event, sortedfilteredUnits, sampleMediaRequest, selectedLearnersList && selectedLearnersList[0])}>
                    <input type="checkbox" checked={checkBoxAll} id="" key="" />
                    <span>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        className="pe-icon--check-sm-18"
                      >
                        <use xlinkHref="#check-sm-18" />
                      </svg>
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Select
                  </div>
                }
              </th>
            </tr>
          </thead>
          {""}
          <tbody>
            {sortedfilteredUnits &&
              sortedfilteredUnits.map((filteredUnit: LearnerUnit, index) => (
                <SampleMediaRequestFilesListRow
                  key={filteredUnit.fileIds + ":" + index}
                  mediaRequestFiles={filteredUnit}
                  onSelectLearner={onSelectLearner}
                  isConsortia={isConsortia}
                  sampleMediaRequest={sampleMediaRequest}
                  mediaRequestFileSelected={mediaRequestFileSelected}
                  selectedLearnersList={selectedLearnersList}
                  mediaRequestStatus={checkRequestStatus(sampleMediaRequestStatus)}
                />
              ))}
          </tbody>
        </Table>
      </div>
      <div className="pull-right">
        <br />
        <Button
          onClick={(e) => closeLearnerModalHandler(e)}
          variant="primary"
        >
          Close
        </Button>
      </div>
    </div>
  );
};


const filter = (
  filteredUnits: LearnerUnit[] | null,
  searchText: string
): LearnerUnit[] | null => {
  const searchTerm = searchText;
  if (searchTerm !== "" && searchTerm.length >= 3) {
    const mediaRequests = JSON.parse(JSON.stringify(filteredUnits));
    const columnNames = [
      "learningProviderCode",
      "learningProviderName",
      "learnerCode",
      "firstName",
      "surname"
    ];
    return _.filter(mediaRequests, mediaRequest => {
      return _(mediaRequest)
        .pick(columnNames)
        .some(searchstring => {
          return _(searchstring)
            .toLower()
            .includes(searchTerm);
        });
    }) as LearnerUnit[] | null;
  }
  return filteredUnits;
};


const sort = (
  filteredUnits: LearnerUnit[] | null,
  selectedColumn: string,
  direction: string
): LearnerUnit[] | null => {
  const lodashDirection = direction === "down" ? "desc" : "asc";
  const sortedSampleMediaRequestFiles = _.orderBy(
    filteredUnits,
    [
      mediaRequestFile => {
        // Added this condition to counteract page crash if
        // field has no value
        if (!mediaRequestFile[selectedColumn]) {
          return null;
        }
        if (
          typeof mediaRequestFile[selectedColumn].toLowerCase === "function"
        ) {
          return mediaRequestFile[selectedColumn].toLowerCase();
        } else {
          return mediaRequestFile[selectedColumn];
        }
      }
    ],
    [lodashDirection]
  ) as LearnerUnit[] | null;
  return filter(sortedSampleMediaRequestFiles, "");
};

const checkRequestStatus = (sampleMediaRequestStatus: string): boolean => {
  // The following statuses of a request will decide whether upload icon, remove file, 
  // link learners checkboxes incl select all will be displayed
  const requestStatusProhibited = [requestStatus.SUBMIT_PENDING, requestStatus.UPLOAD_SUBMITTED];
  return _.includes(requestStatusProhibited, sampleMediaRequestStatus)
}

export default LearnerMediaFileList;
